export * from './defaultTheme'
export * from './countries'
export * from './states'
export * from './currency'
export * from './languages'
export * from './questionTypes'

export const TOKEN_HOURS = 24

export const MAX_VIDEO_SIZE = 100

export const SELECTED = 'selected'

export const OTHER = 'other'

export const TWO_AFC = 'twoAFC'

export const SOCIAL_SOURCE = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook'
}

export const SOCIAL_STATUS = {
  CONNECTED: 'connected'
}

export const ENROLLMENT_STATE = {
  REJECTED: 'rejected',
  FINISHED: 'finished',
  WAITING_FOR_PRODUCT: 'waiting-for-product'
}

export const ENROLLMENT_PROCESS_STATUS = {
  COMPLETED: 'complete',
  NOT_COMPLETE: 'not_complete',
  CAN_RESUME: 'can_resume'
}

export const SHARE_TO = {
  FACEBOOK: 'http://www.facebook.com/share.php?display=page&u=',
  TWITTER: 'https://twitter.com/intent/tweet?via=FlavorWiki&url='
}

export const SURVEY_STATE = {
  ACTIVE: 'active',
  DRAFT: 'draft',
  SUSPENDED: 'suspended'
}

export const AUTHORIZATION_TYPE = {
  PUBLIC: 'public',
  ENROLLMENT: 'enrollment',
  SELECTED: 'selected'
}

export const QUESTION_SECTION = {
  MIDDLE: 'middle'
}

export const OPTION_TYPE = {
  CHECKBOX: 'checkbox',
  RADIO: 'radio'
}

export const DISPLAY_TYPE = {
  LABEL_ONLY: 'labelOnly',
  IMAGE_ONLY: 'imageOnly',
  HIDE_LABEL: 'hideLabel',
  LIST: 'list'
}

export const UPLOAD_STATUS = {
  UPLOADING: 'uploading',
  ERROR: 'error',
  DONE: 'done'
}

export const PRODUCT_DISPLAY_TYPES = {
  NONE: 'none',
  REVERSE: 'reverse',
  PERMUTATION: 'permutation',
  FORCED: 'forced',
  RANDOMISE: 'randomise'
}

export const CUSTOM_STATUS = {
  enable: false,
  beforeSurveyStart: '',
  duringSurvey: '',
  afterFinish: '',
  afterRejection: '',
  inChooseProduct: '',
  inSelectedProduct: '',
  afterExpiry: ''
}

export const PHONE_REG_EXP =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const COLUMN_REWARD = {
  VALID: 'valid',
  INVALID: 'invalid'
}

// these countries will have the 2FA enabled by force on login
// export const TWO_FACTOR_AUTH_COUNTRIES = ['IN', 'PK']
export const TWO_FACTOR_AUTH_COUNTRIES = []
export const ATTEMPTS_LEFT = 3
export const INTERVAL = 1000
export const NO_PAYPAL_COUNTRY = [
  'BR',
  'CN',
  'ET',
  'GH',
  'GN',
  'NG',
  'KR',
  'CI'
]
export const PAYPAL_EMAIL_BLOCKED_TLDS = [
  'af',
  'by',
  'cu',
  'ir',
  'iq',
  'mm',
  'kp',
  'ru',
  'su',
  'рф',
  'pф',
  'sy',
  'ua',
  've',
  'ye'
]

export const TIME_INTENSITY_SLIDER_INTERVAL = 1

export const DISPLAY_ON = {
  SCREENING: 'screening',
  BEGIN: 'begin',
  MIDDLE: 'middle',
  END: 'end',
  PAYMENTS: 'payments',
  REJECTED: 'rejected',
  FINISHED: 'finished'
}

export const FLAVORWIKI_SITE = 'https://flavorwiki.com'
export const META_IMAGE = 'https://flavorwiki-storage-production.s3-eu-west-1.amazonaws.com/Link+Sharing.png'
export const BUSINESS_SITE = 'https://business.flavorwiki.com'
export const FB_REVIEW = 'https://www.facebook.com/FlavorWiki/reviews/'
export const TP_REVIEW = 'https://www.trustpilot.com/review/flavorwiki.com'
export const GOOGLE_REVIEW = 'https://g.page/r/CVPN_5-P9eY4EAE'
export const REACT_PUBLIC_LINKEDIN = 'https://www.linkedin.com/company/flavorwiki'
export const REACT_PUBLIC_TWITTER = 'https://twitter.com/FlavorWiki'

