import { POST } from '@/utils/axios'

export const getProfileChartData = async formData =>
  await POST('/profile-chart', formData)

export const getTetradCompletionQuestionData = async formData =>
  await POST('/get-tetrad-completion-screen', formData)

export const submitAnswer = async formData => await POST('/next-step', formData)

export const goBackAPI = async ({ enrollmentId }) =>
  await POST('/navigate', { direction: 'back', enrollmentId })

export const goForwardAPI = async ({ enrollmentId }) =>
  await POST('/navigate', { direction: 'forward', enrollmentId })

export const getReviewContent = async ({
  surveyId,
  enrollmentId,
  displayOn,
  productId
}) =>
  await POST('/review-answers', {
    surveyId,
    enrollmentId,
    displayOn,
    productId
  })

export const finishReviewAPI = async ({ enrollmentId }) =>
  await POST('/finish-review', { enrollmentId })

export const updateAnswer = async formData =>
  await POST('/update-answers', formData)
